<template>
  <v-container>
    <v-row justify="center" class="mb-7">
      <v-col class="text-center">
        <h1 class="text-h4">CONDITIONS DE VENTE</h1>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-col class="text-center">
        <h3 class="text-subtitle-2 text-decoration-underline">
          Je dessine exclusivement:
        </h3>
        <ul>
          <li>• les félidés</li>
          <li>• les canidés</li>
          <li>• primates (lémuriens...)</li>
          <li>
            • les dragons et autres animaux imaginaires/hybrides (phénix, etc.)
          </li>
          <li>• dans un cadre plus général, les animaux...</li>
        </ul>
        Je suis moins à l'aise avec l'anthropomorphe mais si cela vous tient à
        cœur je fais toujours de mon mieux ! <br />Je suis également peu à
        l'aise avec l'anatomie des rongeurs, lapin, furets, ours... Mais il ne
        faut pas hésiter à demander tout de même ! Je suis ouverte à un univers
        plus large et serai ravie d'élargir mes connaissances en dessin ! À
        condition que je sois inspirée et que je m'en sente capable. <br />Les
        décors ne sont également pas mon fort, cependant je prends du plaisir à
        en faire si on me le décrit !
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-col class="text-center">
        <h3 class="text-subtitle-2 text-decoration-underline">
          Je ne dessinerai PAS:
        </h3>
        <ul>
          <li>• NSFW</li>
          <li>
            • Humains (je ne me sens pas encore prête à accepter des commandes
            de la sorte)
          </li>
          <li>
            • Sujets offensants et dégradants ou «politiquement incorrects»
          </li>
          <li>• Fan art de licences existantes</li>
          <li>• Gore extrême (le sang est accepté...)</li>
        </ul>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-col class="text-center">
        <h3 class="text-subtitle-2 text-decoration-underline">
          Pour prendre commande je demande:
        </h3>
        <ul>
          <li class="mb-2">
            Une référence claire et récente du personnage, ou autre dessin
            montrant lisiblement la morphologie, les couleurs, les éventuels
            accessoires.
          </li>
          <li class="mb-2">
            L'expression du personnage, sa position... Si vous n'avez pas
            d'idée, décrivez moi le caractère de votre personnage. Une
            ambiance...
          </li>
          <li class="mb-2">
            Pour un décor, je vous demande une description plus précise.
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-col class="text-center">
        <h3 class="text-subtitle-2 text-decoration-underline">Paiement:</h3>
        <ul>
          <li>
            - Par Paypal exclusivement (en EUROS), le paiement s'effectue avant
            que je ne commence à dessiner. (attention, je n'accepterai pas de retoucher plus de 2
            fois un croquis, ou un coût supplémentaire sera ajouté la commande.
            Le croquis ne vous appartient pas.)
          </li>
          <li>
            - Payez uniquement lorsque vous avez pris contact avec moi et que
            j'ai validé et accepté votre commission. Voyons !
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-col class="text-center">
        <h3 class="text-subtitle-2 text-decoration-underline">Je dispose:</h3>
        <ul>
          <li>
            • De tous les droits sur le dessin en question ! Je le posterai sur
            mes réseaux sociaux. Et il y aura ma signature sur celui-ci.
            Interdiction de la retirer. Le dessin cependant t'appartiendra en
            tant que tel (et non pas la création de celui-ci), tu auras le droit
            de le poster à ton tour, en n'oubliant pas de me créditer :) (lien
            de mon Artstation, nom...)
          </li>
          <li>
            • Du droit de refuser une commande si celle-ci ne m'inspire pas ou
            ne correspond pas du tout à ce que je suis capable de faire.
          </li>
          <li>
            • Du droit de refuser une commande si la référence donnée pour votre
            personnage n'est pas du tout claire et ne permet pas une claire
            lisibilité.
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-col class="text-center">
        <h3 class="text-subtitle-2 text-decoration-underline">Délais:</h3>
        <ul>
          <li>
            Selon la demande, je peux prendre plus ou moins de temps. Inutile de
            venir me demander chaque jour où en est l'avancement. Vous serez mis
            au courant de toute avancée dans votre commande (Notamment étape par
            étape, sketch, line, colo de base, shading... background si
            demandé.)
          </li>
          <li>
            Je vous enverrai également l'avancée si petite modification est
            demandée, (ex: erreur dans le marking du personnage, préférence pour
            une ambiance de couleur différente, etc.) afin de m'assurer de
            convenir à votre envie.
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-col class="text-center">
        <h3 class="text-subtitle-2 text-decoration-underline">
          Remboursement:
        </h3>
        <ul>
          <li>
            Dès lors qu'un dessin sera commencé, même à partir du croquis, je
            refuse de vous reverser le montant donné pour la réalisation de la
            commande. Si vous me choisissez pour votre commande, vous prenez en
            compte mon style de dessin, le délais possible, etc. Je n'accepterai
            donc pas que vous vous retiriez uniquement car cela ne vous plaît
            finalement plus. Je vis de cet argent et je ne ferai face à aucune
            arnaque.
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-col class="text-center">
        <ul>
          <li>Merci d'avoir lu et au plaisir de dessiner pour vous.</li>
          <li>
            Pour toute autre question, envoyez-moi un message sur l'un de mes
            réseaux, ou un mail !
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({})
}
</script>

<style>
ul {
  list-style-type: none;
}
</style>
